import React from 'react';

import Slide1 from 'src/slides/desktop/hu/chapter_4/sub_2/slide1';
import WrapperMobile from 'src/slides/mobile/hu/chapter_4/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Ki volt Mihail Tuhacsevszkij | A varsói csata" lang="pl" description="A bolsevik Napóleon sziluettje, aki lángba borította volna Európát." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Ki volt Mihail Tuhacsevszkij | A varsói csata" lang="pl" description="A bolsevik Napóleon sziluettje, aki lángba borította volna Európát." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
